@use './_colors.scss';
@use './_animations.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

$font-poppins: 'Poppins', sans-serif;

::root {
  --toastify-font-family: $font-poppins;
}

body {
  font-family: $font-poppins;
  user-select: none;
  -webkit-user-select: none;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

#root,
.home-layout {
  height: 100%;
  min-height: 100vh;
}

.color-blue {
  color: colors.$color-primary;
}

.color-green {
  color: colors.$color-secondary;
}

.input {
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  font-family: $font-poppins;
}
.read_only {
  cursor: not-allowed !important;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.ml {
  margin-left: 15px;
}
.mr {
  margin-right: 15px;
}
.mt {
  margin-top: 15px;
}
.tc {
  text-align: center;
}
.flex {
  display: flex;
}
.jc {
  justify-content: center !important;
}
.je {
  justify-content: flex-end !important;
}
.p-0 {
  padding: 0 !important;
}
.pr {
  padding-right: 15px;
}
.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.text-small {
  font-size: 12px;
  font-weight: 400;
}
.text-regular {
  font-size: 14px;
  font-weight: 400;
}
.text-big {
  font-size: 16px;
  font-weight: 600;
}
.text-bigger {
  font-size: 18px;
  font-weight: 600;
}
.text-bold {
  font-weight: 600;
}
.text-capitalize {
  text-transform: capitalize;
}
.color-primary {
  color: colors.$color-primary;
}
.color-black {
  color: colors.$color-black;
}
.color-grey {
  color: colors.$color-grey;
}
.color-grey-medium {
  color: colors.$color-grey-medium;
}
.MuiDataGrid-columnHeaderTitle {
  color: colors.$color-grey-medium;
  font-weight: 600 !important;
}
.pa {
  position: absolute;
}
.vc {
  top: 50%;
  transform: translateY(-50%);
}
.clickeable {
  cursor: pointer;
}
.input_disabled {
  cursor: not-allowed;
  color: colors.$color-grey !important;
}

@mixin gradient {
  background: rgb(10, 180, 150);
  background: -moz-linear-gradient(
    0deg,
    rgba(10, 180, 150, 1) 20%,
    rgba(171, 216, 124, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(10, 180, 150, 1) 20%,
    rgba(171, 216, 124, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(10, 180, 150, 1) 20%,
    rgba(171, 216, 124, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0ab496",endColorstr="#abd87c",GradientType=1);
}

@mixin primary-shadow {
  -webkit-box-shadow: 0px 15px 25px 0px rgba(colors.$color-primary, 0.2);
  -moz-box-shadow: 0px 15px 25px 0px rgba(colors.$color-primary, 0.2);
  box-shadow: 0px 15px 25px 0px rgba(colors.$color-primary, 0.2);
}

@mixin black-shadow {
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.12);
}

.MuiRadio-root {
  padding: 0 9px 0 9px;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiChip-root {
  background-color: rgba(colors.$color-primary, 0.5) !important;
}
.Mui-selected {
  background-color: rgba(colors.$color-primary, 0.5) !important;
}
.MuiCheckbox-root {
  color: colors.$color-primary !important;
}
.MuiSelect-select {
  font-weight: 600 !important;
}
.btn {
  min-height: 39px;
  text-align: center;
  width: fit-content;
  width: -moz-fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  font-weight: 400;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &_span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (resolution: 1.5dppx) {
  .text-small {
    font-size: 0.8em;
  }
}
